<template>
  <label class="relative inline-flex items-center cursor-pointer">
    <input
      v-model="value"
      type="checkbox"
      class="sr-only peer"
      :true-value="true"
      :false-value="false"
    />
    <div class="h-6 transition-colors duration-300 bg-gray-300 rounded-full w-11 peer peer-focus:ring-blue-300 peer-checked:bg-main-600" />
    <div class="absolute left-0.5 top-0.5 w-5 h-5 bg-white rounded-full transition-transform peer-checked:translate-x-5" />
  </label>
</template>

<script setup lang="ts">
const value = defineModel()
</script>
