<template>
  <div class="flex flex-col pt-2 border-t border-gray-200">
    <div class="flex items-center justify-between">
      <span class="flex-grow typo-basics-1">{{ providerDetails.name }}</span>
      <CommonToggleButton v-model="activated" />
      <SVGArrowDown
        class="w-5 h-5 ml-2 cursor-pointer"
        :class="{ 'rotate-180': descriptionVisible }"
        @click="toggleDescription"
      />
    </div>
    <div v-if="descriptionVisible" class="mt-2 typo-basics-2">
      {{ providerDetails.description }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { GranularConsent } from '@russmedia-frontend/nordstern-embeds'
import { getProviderDetails } from '@russmedia-frontend/nordstern-embeds'

const props = defineProps<{
  providerKey: GranularConsent
  value: boolean | string
}>()

const emit = defineEmits<{
  (event: 'updateEmbedProviderClicked', provider: GranularConsent, value: string | boolean): void
}>()

const descriptionVisible = ref(false)
const activated = ref(['1', true, 1, 'true'].includes(props.value))

function toggleDescription() {
  descriptionVisible.value = !descriptionVisible.value
}

const providerDetails = computed(() => getProviderDetails(props.providerKey as GranularConsent))

watch(activated, () => {
  emit('updateEmbedProviderClicked', props.providerKey, activated.value)
})
</script>
