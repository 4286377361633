<template>
  <section>
    <div class="flex items-center justify-between">
      <SVGSettings class="w-5 h-5" />
      <h2 class="typo-artikel-section-2">
        Präferenzen
      </h2>
      <SVGClose class="w-5 h-5 cursor-pointer" @click="closeModal" />
    </div>

    <div v-if="providers" class="flex flex-col gap-2 mt-4">
      <ModalModalsPurePreferencesItem
        v-for="[key, value] in providerEntries"
        :key="key"
        :provider-key="key"
        :value="value"
        @update-embed-provider-clicked="onUpdateEmbedProviderClicked"
      />
    </div>

    <NPCommonDefaultButton class="mt-4" @click="save">
      Speichern
    </NPCommonDefaultButton>
  </section>
</template>

<script setup lang="ts">
import type { GranularConsent } from '@russmedia-frontend/nordstern-embeds'

const { closeModal } = useModal()

const newGdprSettings = ref(useGdpr().getSettings().value ?? {})

const providers = computed(() => useGdpr().getSettings().value?.granular ?? {})
const providerEntries = computed(() => Object.entries(providers.value) as [GranularConsent, boolean][])

function save() {
  useGdpr().setSettings(newGdprSettings.value)
  usePureSubscription().saveConsents()
  closeModal()
}

function onUpdateEmbedProviderClicked(provider: GranularConsent, value: string | boolean) {
  if (!newGdprSettings.value.granular) {
    newGdprSettings.value.granular = {}
  }

  newGdprSettings.value.granular[provider] = value
}
</script>
